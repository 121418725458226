import React from 'react'
import { Phone } from 'styled-icons/icomoon/Phone'

import * as S from './styled'

function i18nBar () {
  return (
    <S.I18nWrapper>
      <S.ResponsiveContainer>
        <S.Address>
          <S.Phone href='tel:+552199985 9178'>
            <Phone size='16' /> +55 (21) 99985 9178
          </S.Phone>
        </S.Address>
        <S.LanguageContainer>
          <S.Language className='active'>PT</S.Language>
          <S.Language>EN</S.Language>
        </S.LanguageContainer>
      </S.ResponsiveContainer>
    </S.I18nWrapper>
  )
}

export default i18nBar  
