import styled from 'styled-components'
import media from 'styled-media-query'

export const I18nWrapper = styled.section`
  height: 30px;
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--white);
  font-size: .9em;
`

export const Address = styled.div`
  text-align: left;
`

export const Phone = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  text-decoration: none;

  svg {
    margin-right: 6px;
  }
`

export const LanguageContainer = styled.div`
  text-align: right;
`

export const ResponsiveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--maxWidth);
  background-color: inherit;
  margin: 0 auto;
  height: inherit;

  ${media.lessThan('huge')`
    padding: 0 var(--mobileGlobalPadding);
  `}
`

export const Language = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--white);
  font-weight: 700;
  cursor: pointer;

  &:first-of-type {
    margin-right: 6px;
  }

  &.active {
    background-color: var(--white);
    color: var(--primaryColor);
  }
`
