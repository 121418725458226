import React from 'react'

import * as S from './styled'

function Header () {
  return (
    <S.Header>
      <S.Title>Vento Sul 3</S.Title>
      <S.Slogan>A sua chave para o prazer</S.Slogan>
    </S.Header>
  )
}

export default Header