import styled from 'styled-components'

export const LayoutWrapper = styled.section`
  display: flex;
  flex-direction: column;
`

export const LayoutMain = styled.main`
  background-color: var(--white);
  min-height: 100vh;
  width: 100%;
`