import React from "react"
import PropTypes from "prop-types"

import GlobalStyles from '../../styles/global.js'

import * as S from './styled'
import Internacionalization from '../i18nBar'
import Header from '../Header'

function Layout ({ children }) {
  return (
    <S.LayoutWrapper>
      <GlobalStyles />
      <Internacionalization />
      <Header />

      <S.LayoutMain>{ children }</S.LayoutMain>
    </S.LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
