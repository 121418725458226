import styled from 'styled-components'
import media from 'styled-media-query'

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 120px;
  background-color: var(--white);

  ${media.lessThan('huge')`
    padding: 0 var(--mobileGlobalPadding);
  `}
`

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 700;
  font-family: 'Courgette', cursive;
  color: var(--primaryColor);
`

export const Slogan = styled.span`
  margin: 10px 0 0;
  font-family: 'Roboto', sans-serif;
  color: var(--primaryColor);
`
